<template>
	<div class="login-container">
		<el-form :model="loginForm" :rules="loginRules" auto-complete="on" class="login-form" label-position="left" ref="loginForm">
			<h3 class="title">西瓜YouTube管理平台</h3>
			<el-form-item prop="account">
				<span class="svg-container"><svg-icon icon-class="user"/></span>
				<el-input auto-complete="on" name="account" placeholder="账号" type="text" v-model="loginForm.account"/>
			</el-form-item>
			<el-form-item prop="password">
				<span class="svg-container"><svg-icon icon-class="password"/></span>
				<el-input :type="pwdType" @keyup.enter.native="loginRequest" auto-complete="on" name="password" placeholder="密码" v-model="loginForm.password"/>
				<span @click="showPwd" class="show-pwd"><svg-icon :icon-class="eyeType"/></span>
			</el-form-item>
			<el-form-item>
				<el-button :loading="loading" @click.native.prevent="loginRequest" style="width:100%;" type="primary">登陆</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import {Message} from 'element-ui';

export default {
	name: "Login",
	data() {
		const validatePass = (rule, value, callback) => {
			if (value.length < 5) {
				callback(new Error("密码不能小于5位"));
			} else {
				callback();
			}
		};
		return {
			loginForm: {
				account: "",
				password: ""
			},
			loginRules: {
				account: [{required: true, message: '请输入账号', trigger: "blur"}],
				password: [{required: true, trigger: "blur", validator: validatePass}]
			},
			loading: false,
			pwdType: "password",
			redirect: undefined,
			eyeType: 'eye-close',
			showTencentCaptcha: false
		};
	},
	watch: {
		$route: {
			handler: function (route) {
				this.redirect = route.query && route.query.redirect;
			},
			immediate: true
		}
	},
	methods: {
		showPwd() {
			if (this.pwdType === "password") {
				this.pwdType = "";
				this.eyeType = 'eye-open';
			} else {
				this.pwdType = "password";
				this.eyeType = 'eye-close'
			}
		},
		loginRequest() {
			this.$refs.loginForm.validate(valid => {
				if (valid) {
					this.loading = true;
					this.$store
						.dispatch("Login", this.loginForm)
						.then(() => {
							this.loading = false;
							this.$router.push({path: this.redirect || "/"});
						})
						.catch((error) => {
							if (error.response.status === 401) {
								Message.closeAll();
								Message({
									message: "用户名或密码错误，请重试",
									type: 'error',
									center: true,
									duration: 5 * 1000
								});
							}
							this.loading = false;
						});
				} else {
					return false;
				}
			});
		}
	}
};
</script>

<style lang="less" rel="stylesheet/less">
@bg: #2d3a4b;
@light_gray: #eee;

/* reset element-ui css */
.login-container {
	.el-input {
		display: inline-block;
		height: 47px;
		width: 85%;

		input {
			background: transparent;
			border: 0;
			-webkit-appearance: none;
			border-radius: 0;
			padding: 12px 5px 12px 15px;
			color: @light_gray;
			height: 47px;

			&:-webkit-autofill {
				-webkit-box-shadow: 0 0 0 1000px @bg inset !important;
				-webkit-text-fill-color: #fff !important;
			}
		}
	}

	.el-form-item {
		border: 1px solid rgba(255, 255, 255, 0.1);
		background: rgba(0, 0, 0, 0.1);
		border-radius: 5px;
		color: #454545;
	}
}
</style>

<style lang="less" rel="stylesheet/less" scoped>
@bg: #2d3a4b;
@dark_gray: #889aa4;
@light_gray: #eee;
.login-container {
	position: fixed;
	height: 100%;
	width: 100%;
	background-color: @bg;

	.login-form {
		position: absolute;
		left: 0;
		right: 0;
		top: 100px;
		width: 520px;
		max-width: 100%;
		padding: 15px 35px;
		margin: 0 auto;
	}

	.svg-container {
		padding: 6px 5px 6px 15px;
		color: @dark_gray;
		vertical-align: middle;
		width: 30px;
		display: inline-block;
	}

	.title {
		font-size: 26px;
		/*font-weight: 400;*/
		color: @light_gray;
		margin: 0 auto 40px auto;
		text-align: center;
		font-weight: bold;
	}

	.show-pwd {
		position: absolute;
		right: 10px;
		top: 7px;
		font-size: 16px;
		color: @dark_gray;
		cursor: pointer;
		user-select: none;
	}
}
</style>
